import { UsuarioModel } from "src/app/common/models/usuario.model";
import { GrupoModel } from "./grupo.model";

export class UsuarioGrupoModel {
  constructor(
    public id?: number,
    public grupo_id?: number,
    public usuario_id?: number,
    public ativo?: boolean,
    public created_at?: Date,
    public updated_at?: Date,

    public grupo?: GrupoModel,
    public usuario?: UsuarioModel
  ) { }
}
