import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { PopulacaoExpostaModel } from '../models/populacao-exposta.model';

@Injectable()
export class PopulacaoExpostaService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  private api: string = this.apiUrl + '/populacaoExposta';

  public buscar() {
    return this.http.get<PopulacaoExpostaModel[]>(this.api);
  }

  public cadastrar(obj: PopulacaoExpostaModel) {
    return this.http.post<any>(this.api, obj);
  }

  public atualizar(obj: PopulacaoExpostaModel) {
    return this.http.put<any>(this.api, obj);
  }

  public remover(id: number) {
    return this.http.delete<any>(sprintf(this.api.concat('/%u'), id));
  }

  public buscarDetalhe(id: number) {
    return this.http.get<PopulacaoExpostaModel>(sprintf(this.api.concat('/%u'), id));
  }
}

