import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { FontePerigoModel } from '../models/fonte-perigo.model';

@Injectable()
export class FontePerigoService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  private api: string = this.apiUrl + '/fontePerigo';

  public buscar() {
    return this.http.get<FontePerigoModel[]>(this.api);
  }

  public cadastrar(obj: FontePerigoModel) {
    return this.http.post<any>(this.api, obj);
  }

  public atualizar(obj: FontePerigoModel) {
    return this.http.put<any>(this.api, obj);
  }

  public remover(id: number) {
    return this.http.delete<any>(sprintf(this.api.concat('/%u'), id));
  }

  public buscarDetalhe(id: number) {
    return this.http.get<FontePerigoModel>(sprintf(this.api.concat('/%u'), id));
  }
}

