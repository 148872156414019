import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { ControleRiscoModel } from '../models/controle-risco.model';

@Injectable()
export class ControleRiscoService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  private api: string = this.apiUrl + '/controleRisco';

  public buscar(empresaId: number) {
    return this.http.get<ControleRiscoModel[]>(sprintf(this.api.concat("/empresa/%u"), empresaId));
  }

  public cadastrar(obj: ControleRiscoModel) {
    return this.http.post<ControleRiscoModel>(this.api, obj);
  }

  public atualizar(obj: ControleRiscoModel) {
    return this.http.put<ControleRiscoModel>(this.api, obj);
  }

  public buscarDetalhe(id: number, empresa_id: number) {
    return this.http.get<ControleRiscoModel[]>(sprintf(this.api.concat('/%u/%u'), id, empresa_id));
  }

  public uploadArquivo(file: any, id: number) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<any>(sprintf(this.api.concat('/upload/%u'), id), formData, { headers });
  }

  public processarPlanilha(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<any>(sprintf(this.api.concat('/processarPlanilha')), formData, { headers });
  }

}

