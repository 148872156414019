import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { FatorRiscoModel } from '../models/fator-risco.model';

@Injectable()
export class FatorRiscoService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  private api: string = this.apiUrl + '/fatorRisco';

  public buscar() {
    return this.http.get<FatorRiscoModel[]>(this.api);
  }

  public cadastrar(obj: FatorRiscoModel) {
    return this.http.post<any>(this.api, obj);
  }

  public atualizar(obj: FatorRiscoModel) {
    return this.http.put<any>(this.api, obj);
  }

  public remover(id: number) {
    return this.http.delete<any>(sprintf(this.api.concat('/%u'), id));
  }

  public buscarDetalhe(id: number) {
    return this.http.get<FatorRiscoModel>(sprintf(this.api.concat('/%u'), id));
  }

  public processarPlanilha(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<any>(sprintf(this.api.concat('/processarPlanilha')), formData, { headers });
  }

}

